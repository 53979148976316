<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑分类' : '创建分类'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input
            size="mini"
            v-model.trim="form.data.name"
            placeholder="请输入分类名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            size="mini"
            v-model.trim="form.data.sort"
            placeholder="请输入排序"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="上传图标" prop="icon_url" class="idPhoto">
          <el-upload
            action=""
            :headers="{ token: token }"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="httpRequestFront"
            accept="image/jpeg, image/png, image/gif"
            >
            <img v-if="this.form.data.icon_url" :src="this.form.data.icon_url" class="avatar" style="object-fit: contain;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <!-- 只允许输入数字 -->
          <el-input
            size="mini"
            v-model.trim="form.data.code"
            placeholder="请输入"
            clearable
            @input="form.data.code=form.data.code.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="税率" prop="rate">
          <!-- 允许输入两位小数的数字 -->
          <el-input
            size="mini"
            v-model.trim="form.data.rate"
            placeholder="请输入"
            clearable
            @input="form.data.rate=form.data.rate.match(/\d+(\.\d{0,2})?/) ? form.data.rate.match(/\d+(\.\d{0,2})?/)[0] : ''"
          >
          <span slot="suffix">%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            size="mini"
            v-model.trim="form.data.remark"
            placeholder="请输入"
            clearable
            autosize
            maxlength="200"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.data.status">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { getStorage } from '@/storage'
  export default {
    name: 'companyContractEdit',
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            sort: '',
            icon_url: '',
            code: '',
            rate: '',
            remark: '',
            status: 1,
          },
          rules: {
            name: [{ required: true, message:'请输入分类名称', trigger: 'change'}],
            sort: [{ required: true, message:'请输入序号', trigger: 'change'}],
            icon_url: [{ required: true, message:'请上传图标', trigger: 'change'}],
            code: [{ required: true, message:'请输入编码', trigger: 'change'}],
            rate: [{ required: true, message:'请输入分类', trigger: 'change'}],
            status: [{ required: true, message:'请选择状态', trigger: 'change'}],
          }
        },
        dialogImageUrl: [],
        dialogVisible: false,
        img_value: [],
      }
    },
    methods: {
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let apiUrl = this.isChange ? '/admin/cate/edit' : '/admin/cate/create'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传图片大小不能超过 10MB!');
        }
        return isLt10M;
      },
      httpRequestFront(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.icon_url = res.data.all_path_url;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
     
      // 图片上传成功
      handleAvatarSuccess(event, file, fileList) {
        if (event.code == 200) {
          this.form.data.icon_url = event.data.path[0];
        } else {
          this.$message({
            type: "error",
            message: event.msg,
          });
        }
      },
      // 移除图片
      handleRemove(file, fileList) {
        this.form.data.icon_url = "";
        this.img_value = [];
      },
    }
  }
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /* .idPhoto .avatar{
    width: 100% !important;
  } */
  .idPhoto .avatar-uploader-icon {
    width: 178px !important;
    height: 178px !important;
    line-height: 178px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>
