var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-view"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('el-form',{ref:"elFormDom",attrs:{"inline":"","model":_vm.table.params,"size":_vm.subUnitSize},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"商品分类","prop":"name"}},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入商品分类","clearable":""},on:{"clear":_vm.onSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.table.params.name),callback:function ($$v) {_vm.$set(_vm.table.params, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"table.params.name"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.onSearch}},[_vm._v("搜索")]),_c('el-button',{attrs:{"icon":"el-icon-refresh-right"},on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1),_c('div',{staticClass:"btnAdd"},[_c('el-button',{attrs:{"size":_vm.subUnitSize,"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.showEdit}},[_vm._v("创建分类")])],1)],1),_c('VTable',{attrs:{"has-pagionation":"","field":_vm.field,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"tree-props":{children: 'children', hasChildren: 'has_child'},"hasPagionation":true,"default-expand-all":true,"showSelect":false},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"icon_url",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":row.icon_url,"fit":"cover","preview-src-list":[row.icon_url]}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":2},on:{"change":function($event){return _vm.handleStatus(row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}},{key:"rate",fn:function(ref){
var row = ref.row;
return [(!!row.rate)?_c('span',[_vm._v(_vm._s(row.rate)+"%")]):_vm._e()]}},{key:"sorter",fn:function(ref){
var row = ref.row;
return [(row.sorter && row.sorter.length > 0)?_c('span',_vm._l((row.sorter),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < row.sorter.length - 1)?_c('span',[_vm._v("、")]):_vm._e()])}),0):_c('div')]}},(_vm.roleType == 4)?{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.remove(row)}}},[_vm._v("删除")])]}}:null],null,true)}),_c('edit',{ref:"edit",on:{"refresh":_vm.getTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }