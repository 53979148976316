<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize" @submit.native.prevent>
        <el-form-item label="商品分类" prop="name">
          <el-input
            size="mini"
            v-model.trim="table.params.name"
            placeholder="请输入商品分类"
            clearable
            @clear="onSearch"
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建分类</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:icon_url="{row}">
        <el-image
          style="width: 60px; height: 60px"
          :src="row.icon_url"
          fit="cover"
           :preview-src-list="[row.icon_url]"></el-image>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:rate="{row}">
        <span v-if="!!row.rate">{{row.rate}}%</span>
      </template>
      <template v-slot:sorter="{row}">
        <span v-if="row.sorter && row.sorter.length > 0">
          <span v-for="(item,index) in row.sorter" :key="index">
            {{item}}
            <span v-if="index < row.sorter.length - 1">、</span>
          </span>
        </span>
        <div v-else></div>
      </template>
      <!-- 只有财务角色才有操作，其他角色都没有操作 -->
      <template v-slot:action="{row}" v-if="roleType == 4">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="remove(row)">删除</el-button>
      </template>
    </VTable>

    <!-- 查看详情 -->
    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'
export default {
  name: '',
  components: {
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage('role_type'), // 1:超级管理员,2:管理员,3:员工,4:财务
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      field: [
        { name: "id", label: "ID", width:"80", hidden: false },
        { name: "name", label: "商品分类", hidden: false },
        { name: "icon_url", label: "图标", width:'100', hidden: false},
        { name: "sort", label: "排序",  width:'80', hidden: false},
        { name: "code", label: "编码", hidden: false},
        { name: "rate", label: "税率", width:'100', hidden: false},
        // { name: "sorter", label: "分拣员", showTooltip: true, hidden: false},
        { name: "remark", label: "备注", showTooltip: true, hidden: false},
        { name: "status", label: "状态", width:'100', hidden: false},
        { name: "create_time", label: "创建时间", showTooltip: true, hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          page: 1,
          count: 10
        },
        data: [
          {
            id:1,
            name: '名称'
          }
        ],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  mounted() {
    // 只有财务角色（roleType：4）时才有操作，其他角色时删除最后操作项
    if(this.roleType != 4) {
      this.field = [...this.field.slice(0, -1)];
    }
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.post('/admin/cate/list', this.table.params ).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/cate/delete"
      );
    },
    // 禁用，启用
    handleStatus(row) {
      this.$http.post('/admin/cate/updateStatus', {id: row.id, status: row.status}).then(res => {
        if(res.code == 1) {
          this.$message.success(`${row.status == 1 ? '开启' : '禁用'}成功！`)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable()
      })
    },
  }
}
</script>
<style>
.el-tooltip__popper.is-dark {
  max-width: 600px;
}
</style>
